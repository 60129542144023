.breadcrumb-row {
    background: rgba(255,255,255,0.1);
    padding: 10px 20px;
    border: 1px solid rgba(255,255,255,0.3);
    display: inline-block;
    border-radius: 6px;
}

.breadcrumb-row ul {
    margin: 0;
}
.breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    color: #fff;
	display: inline-block;
}
.breadcrumb-row ul li:after {
    content: "\f105";
    margin-left: 7px;
    margin-right: 7px;
	font-family: fontawesome;
	opacity:0.5;
	
}
.breadcrumb-row ul li:last-child {
    color: #fff;
}
.breadcrumb-row ul li:last-child:after {
    display: none;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 10px;
}
.table.borderless > tbody > tr > td,
.table.borderless > tbody > tr > th {
    border: none;
}
.dataTable .sorting_asc .checkbox {
    margin-right: 0;
}
.banner-bottom{
	width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #fff;
    background-color: #00a99e;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    vertical-align: middle;
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    box-shadow: 0 10px 30px 0 rgba(0,54,255,0.25);
	cursor: pointer;
}